import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import ReactPlayer from 'react-player';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`My 5 Lessons Learned from Jack Butcher`}</h1>
    <p>{`Yo!`}</p>
    <p>{`On Friday, we hosted an AMA with Jack Butcher.`}</p>
    <p>{`His story is simple:`}</p>
    <p>{`He worked at an agency, and thought to himself “man I’m underpaid!” and decided to start his own agency. Then he realized “shit, there’s a lot more to this than I thought!”`}</p>
    <p>{`For his agency, he now was in charge of selling to clients to get business. So he made pitch decks to sell the client on working with his agency.`}</p>
    <p>{`He got so good at making pitch decks, he had a realization.`}</p>
    <p>{`Forget the general agency work. What if I just focused on teaching people how to make great pitches like these?`}</p>
    <p>{`This became `}<strong parentName="p">{`visualize value.`}</strong>{` His dope twitter account and blog that has over 100k+ followers.`}</p>
    <p>{`Twitter fame allowed him to launch a course (pre-sold via Gumroad to see if there was interest), teaching other people how to create compelling visuals for their business.`}</p>
    <p>{`And the paid visualize value community, that according to my guesstimates is doing over $1m+ a year.`}</p>
    <p>{`Jack stopped by to do an AMA. It’s an hour long (`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/p9uo0vkwkqt9hmr4oxaq/m2h7h5hok6zkp5hm/aHR0cHM6Ly92aW1lby5jb20vNDgyODY1ODQ5LzY1ZThiZDg1YTU="
      }}>{`you can watch it here`}</a>{`). But I wanted to share my 5 biggest takeaways:`}</p>
    <ReactPlayer url="https://vimeo.com/482865849/65e8bd85a5" mdxType="ReactPlayer" />
    <br />
    <p><strong parentName="p">{`How did he get big to begin with?`}</strong></p>
    <p>{`I think he called it a `}<strong parentName="p">{`Clout Wagon`}</strong>{`. When you piggyback off someone else’s brand to grow yours. `}</p>
    <p>{`Jack started by creating slick visuals of quotes said by famous people (eg. Naval, Tim Ferriss) etc.. hoping they would retweet it. `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/p9uo0vkwkqt9hmr4oxaq/dpheh0hqz9nzgntm/aHR0cHM6Ly90d2l0dGVyLmNvbS92aXN1YWxpemV2YWx1ZS9zdGF0dXMvMTI5Mzc1MDE1MzQzOTA1NTg3Mz9zPTIw"
      }}>{`(example)`}</a>{`  `}</p>
    <p>{`It didn’t work right away (I went and checked, his first 15 tweets had less than 20 likes). But then he started getting likes from them, which surprisingly drove him followers. Then a few retweets and he was off to the races. Now he has over 100k+ followers in just 18 months.`}</p>
    <p>{`This works because:`}</p>
    <ol>
      <li parentName="ol">{`Famous people have egos, they will like/retweet stuff that makes them look good`}</li>
      <li parentName="ol">{`You don’t have to come up with original content (just cherry pick their good stuff)`}</li>
      <li parentName="ol">{`Their fans become your fans`}</li>
    </ol>
    <p>{`I love this strategy. It's a great 80/20 of content. Less effort, more results.`}</p>
    <p><strong parentName="p">{`“Make one decision that saves you 1000 future decisions”`}</strong></p>
    <p>{`A great quote Jack brought up that he heard from Tim Ferriss.`}</p>
    <p>{`I love this quote. These are the best types of decisions.`}</p>
    <p>{`For example - once you decide (truly decide) to get fit, you don’t have to make daily micro decisions about eating brownies. Great decisions make future decisions easier (or non-existant)`}</p>
    <p><strong parentName="p">{`Is it digestible?`}</strong></p>
    <p>{`Jack is very purposeful about making content easy to digest. He tries not to have too many video lessons in his course, because he knows that people can only consume so much.`}</p>
    <p>{`As they say: “sorry for the long email, i didn’t have time to write a short one”. It takes effort to make things concise and simple. Put in the effort.`}</p>
    <p>{`  `}<strong parentName="p">{`Consistency is the key to any content strategy`}</strong></p>
    <p>{`One tweet, Two blogs, four podcasts… it doesn’t do anything. Consistency is the key to any content strategy. So it’s important to bite off what you can chew. For Jack, those were simple visual diagrams, produced regularly.`}</p>
    <p>{`And even if your content doesn’t take off right away (spoiler, it almost certainly won’t), you become “a more competent practitioner” (he’s so british) because you’re consistently creating content. Over time, you get good at it.`}</p>
    <p><strong parentName="p">{`Every seed produces a specific flower`}</strong></p>
    <p>{`Jack’s idea for community was to put out a certain type of content...which will attract a certain type of person.`}</p>
    <p>{`The content is essentially the filter. He puts out a network of ideas, and like a net, it catches people who are attracted to this type of thinking. This creates a like minded set of individuals to form a community.`}</p>
    <p>{`All in all - good stuff by Jack. Now I’m going to return the favor in his community. If you guys are interested btw...I’m working on a deal that will get you guys access to Jack’s course/community + The Hustle’s Trends + more at a discount. Lmk if that’s something you’d be interested in !`}</p>
    <p>{`-shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      